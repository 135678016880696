<template>
    <div class="room-select" @click.stop="() => {}">
        <div @click="isShowPanel = true" >
            <CSSelect
                height="30px"
                i-width="36px"
                :style="{
                    width: '151px',
                    color: '#000',
                }"
            >
                <div class="checked-rooms">
                    {{
                        checkedRoomNames.length > 0
                            ? checkedRoomNames.toString()
                            : "全部房间"
                    }}
                </div>
            </CSSelect>
        </div>
        <div class="room-select-panel" v-show="isShowPanel">
            <!-- <div class="room-select-panel-header">
                <span class="active">空置</span>
                <span>在租</span>
            </div> -->
            <div class="room-select-panel-content">
                <div class="floor-info">
                    <div @click="checkedRoomNames = []; isShowPanel = false; allRooms()">
                      全部房间
                    </div>
                    <div
                        v-for="building in roomList"
                        :key="building.buildingId"
                    >
                        <div class="building-checkbox-panel">
                            <div
                                @click="
                                    changeUnfold(
                                        'unflodBuilding',
                                        building.buildingId
                                    )
                                "
                            >
                                <div
                                    :class="[
                                        'glyphicon',
                                        'glyphicon-triangle-right',
                                        {
                                            rotate: unflodBuilding.includes(
                                                building.buildingId
                                            ),
                                        },
                                    ]"
                                ></div>
                                <!-- <input
                                    type="checkbox"
                                    v-model="checkedBuildings"
                                    disabled
                                    :value="building.buildingId"
                                /> -->
                                {{ building.buildingName }}
                            </div>
                            <div
                                class="floor-checkbox-panel"
                                v-for="floor in building.floors"
                                :key="floor.floor"
                            >
                                <template
                                    v-if="
                                        unflodBuilding.includes(
                                            building.buildingId
                                        )
                                    "
                                >
                                    <div
                                        @click="
                                            changeUnfold(
                                                'unfoldFloor',
                                                floor.floor
                                            )
                                        "
                                    >
                                        <div
                                            :class="[
                                                'glyphicon',
                                                'glyphicon-triangle-right',
                                                {
                                                    rotate: unfoldFloor.includes(
                                                        floor.floor
                                                    ),
                                                },
                                            ]"
                                        ></div>
                                        <!-- <input
                                            type="checkbox"
                                            v-model="checkedFloors"
                                            disabled
                                            :value="
                                                building.buildingId +
                                                '_' +
                                                floor.floor
                                            "
                                        /> -->
                                        {{ floor.floor }}
                                    </div>
                                    <div
                                        class="room-checkbox-panel"
                                        v-if="unfoldFloor.includes(floor.floor)"
                                    >
                                        <template v-for="room in floor.rooms">
                                            <div
                                                class="room-name"
                                                :key="room.roomId"
                                                @click="
                                                        changecheckedRoomIds(
                                                            room,
                                                            floor,
                                                            building
                                                        )
                                                    "
                                            >
                                                <!-- <input
                                                    type="checkbox"
                                                    :checked="
                                                        checkedRoomIds.includes(
                                                            room.roomId
                                                        )
                                                    "
                                                    :value="room.roomId"
                                                    @change="
                                                        changecheckedRoomIds(
                                                            room,
                                                            floor,
                                                            building
                                                        )
                                                    "
                                                /> -->
                                                {{ room.roomNo }}
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { queryRoomRentUrl } from "@/requestUrl";
import CSSelect from "@/components/common/CSSelect";

export default {
    name: "QueryRoomSelect",
    props: {
        selectedRooms: Array,
    },
    components: {
        CSSelect,
    },
    data() {
        return {
            isShowPanel: false,
            unfoldFloor: [],
            unflodBuilding: [],
            roomList: [],
            checkedRoomIds: [],
            checkedFloors: [],
            checkedBuildings: [],
            checkedRoomNames: [],
            checkedRooms: [],
        };
    },
    watch: {
        async selectedRooms(selectedRooms) {
            const roomList =
                this.roomList.length > 0
                    ? this.roomList
                    : await this.queryRoom();
            roomList.forEach((building) => {
                building.floors.forEach((floor) => {
                    floor.rooms.forEach((room) => {
                        if (selectedRooms.includes(room.roomId)) {
                            this.changecheckedRoomIds(room, floor, building);
                        }
                    });
                });
            });
        },
    },
    methods: {
        // 查询房间列表
        async queryRoom() {
            return this.$fly
                .post(queryRoomRentUrl, {
                    regionId: this.$vc.getCurrentRegion().communityId,
                    
                })
                .then((res) => {
                    this.roomList = res.data.datas;
                    return res.data.datas;
                });
        },
       /**
         * 修改展开/关闭
         * @param {String} property 操作的属性名称
         * @param {Number} id
         *  */
        changeUnfold(property, id) {
            if (this[property].includes(id)) {
                this[property].splice(this[property].indexOf(id), 1);
            } else {
                this[property]=[id]
            }
        },
        /**
         * 更改房间选中状态
         * @param {Object} room 房间信息
         * @prama {Object} floor 楼层信息
         * @param {Object} building 楼宇信息
         *  */
        changecheckedRoomIds(room, floor, building) {
            
            if(!room.roomId){
                this.changeUnfold( 'unflodBuilding',building.buildingId)
                this.changeUnfold('unfoldFloor',floor.floor)
            }

            
            this.checkedRoomNames=[]
            
            this.isShowPanel = false;
            const id = room.roomId,
                fId = floor.floor,
                bId = building.buildingId;
            const deleteIndex = this.checkedRoomIds.indexOf(id);
            if (deleteIndex > -1) {
                this.checkedFloors.splice(deleteIndex, 1);
                this.checkedBuildings.splice(deleteIndex, 1);
                this.checkedRoomNames.splice(deleteIndex, 1);
                this.checkedRooms.splice(deleteIndex, 1);
                this.checkedRoomIds.splice(deleteIndex, 1);
            } else {
                this.checkedFloors = [bId + "_" + fId];
                this.checkedBuildings = [bId];

                this.checkedRooms = [{
                    buildingName: building.buildingName,
                    ...room,
                }];
                this.checkedRoomNames = [`${building.buildingName}-${floor.floor}-${room.roomNo}`];
                this.checkedRoomIds = [id];
            }
            this.$emit("change-rooms", {
                roomIds: this.checkedRoomIds,
                rooms: this.checkedRooms,
            });
        },
        allRooms() {
          this.$emit("change-rooms", {
                roomIds: '',
                rooms: [],
            });
        }
    },
    mounted() {
        document
            .querySelector(".contract-modal")
            .addEventListener("click", () => {
                this.isShowPanel = false;
            });
    },
    created() {
        this.queryRoom();
    },
};
</script>
<style  scoped>
.room-select {
    margin-left: 645px;
    margin-top: -45px;
}

.room-select .custom-input {
    border: 1px solid #979797 !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.checked-rooms {
    max-width: 220px;
    padding: 10px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.room-select-panel {
    /* position: absolute;
    top: 45px;
    left: 0; */
    background: #fff;
    width: 255px;
    height: 280px;
    z-index: 2;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 10px;
}

.room-select-panel-header {
    text-align: center;
    margin-bottom: 25px;
}

.room-select-panel-header span {
    font-size: 24px;
    display: inline-block;
    position: relative;
    width: 48px;
    height: 33px;
    line-height: 33px;
}

.glyphicon.glyphicon-triangle-right {
    font-size: 10px;
    margin-right: 8px;
    vertical-align: middle;
}

.glyphicon.glyphicon-triangle-right.rotate {
    -webkit-animation: arrowRotate 0.1s linear 1;
    animation: arrowRotate 0.1s linear 1;
    transform: rotate(90deg);
}

@-webkit-keyframes arrowRotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
    }
}
@keyframes arrowRotate {
    0% {
        -webkit-transform: rotate(0deg);
    }
    50% {
        -webkit-transform: rotate(45deg);
    }
    100% {
        -webkit-transform: rotate(90deg);
    }
}

.room-select-panel-header span:not(:last-of-type) {
    margin-right: 99px;
}

.room-select-panel-header span.active::after {
    position: absolute;
    width: 60px;
    height: 4px;
    content: " ";
    background: #1ab394;
    border-radius: 2px;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
}

.room-select-panel-content {
    padding: 14px;
    height: 100%;
    overflow: auto;
    color: #000;
}

.room-select-panel input[type="checkbox"] {
    vertical-align: middle;
}

.floor-checkbox-panel input[type="checkbox"] {
    margin-right: 10px;
}

.floor-checkbox-panel {
    padding-left: 20px;
}

.room-checkbox-panel {
    padding-left: 40px;
    margin-bottom: 0;
}
.custom-input {
    width: 250px !important;
    padding: 0px 10px 0 10px;
    position: relative;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: #000;
    max-width: 234px;
}
</style>
